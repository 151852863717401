var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.trendingRepos
    ? _c(
        "div",
        { staticClass: "trending-repos-wrapper" },
        _vm._l(_vm.trendingRepos, function (repo) {
          return _c("div", { key: repo.idx, staticClass: "repo-row" }, [
            repo.avatar
              ? _c("img", {
                  staticClass: "repo-img",
                  attrs: { src: repo.avatar, alt: "Repo" },
                })
              : _vm._e(),
            _c("div", { staticClass: "repo-info" }, [
              _c("p", { staticClass: "repo-name" }, [
                _vm._v(_vm._s(repo.name)),
              ]),
              _c("div", { staticClass: "star-wrap" }, [
                repo.stars
                  ? _c("p", { staticClass: "all-stars" }, [
                      _vm._v(_vm._s(_vm._f("formatStars")(repo.stars))),
                    ])
                  : _vm._e(),
                repo.newStars
                  ? _c("p", { staticClass: "new-stars" }, [
                      _vm._v(
                        "↑" + _vm._s(_vm._f("formatStars")(repo.newStars))
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "a",
                { staticClass: "repo-link", attrs: { href: repo.link } },
                [_vm._v(_vm._s(repo.slug))]
              ),
              _c("p", { staticClass: "repo-desc" }, [
                _vm._v(_vm._s(repo.desc)),
              ]),
            ]),
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }